import moment from 'moment';
import { RRule } from 'rrule';

export type Day =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday';

export type RecurrenceOptions = {
  type: 'daily' | 'weekly' | 'monthly';
  everyNumberOf?: number;
  weekDays?: Array<Day>;
  weekDay?: Day;
  nthDayOfMonth?: number;
  dayOfMonthRange?: 'nthDayOfMonth' | 'nthWeekDayOfMonth';
  dayRange?: 'days' | 'weekdays';
  rangeEndType?: 'date' | 'count';
  weekNumber?: number;
  endDate?: Date;
  count?: number;
};

const getWeekday = (day: Day) => RRule[day.slice(0, 2).toUpperCase()];

// Force the date to UTC; RRule requires UTC dates
// https://github.com/jakubroztocil/rrule#timezone-support
const fixDate = date => moment.utc(moment(date).format('YYYY-MM-DD')).toDate();

const monToFri = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];

const recurrenceRuleBuilderMain = (
  date: Date,
  {
    type = 'daily',
    weekDays = [],
    weekNumber,
    weekDay = 'monday',
    everyNumberOf: interval = 1,
    nthDayOfMonth: monthDay,
    dayOfMonthRange,
    dayRange,
    rangeEndType = 'count',
    endDate = date,
    count = 1,
  }: RecurrenceOptions
): RRule =>
  new RRule({
    dtstart: fixDate(date),
    ...(type === 'daily'
      ? {
          freq: RRule.DAILY,
          ...(dayRange === 'weekdays'
            ? { byweekday: monToFri.map(getWeekday), interval: 1 }
            : { interval }),
        }
      : {}),
    ...(type === 'weekly'
      ? { freq: RRule.WEEKLY, interval, byweekday: weekDays.map(getWeekday) }
      : {}),
    ...(type === 'monthly'
      ? {
          interval: 1,
          freq: RRule.MONTHLY,
          ...(dayOfMonthRange === 'nthDayOfMonth'
            ? {
                // For 29th or later, use closest available day
                // https://github.com/jakubroztocil/rrule/issues/114
                bysetpos: -1,
                bymonthday: Array.from({
                  length: Math.max(1, Number(monthDay) - 27),
                }).map((_, i) => Number(monthDay) - i),
              }
            : { byweekday: getWeekday(weekDay).nth(weekNumber) }),
        }
      : {}),
    ...(rangeEndType === 'date' ? { until: fixDate(endDate) } : { count }),
  });

const numericFields = ['everyNumberOf', 'nthDayOfMonth', 'weekNumber', 'count'];

const numberOrUndefined = value => {
  const number = Number(value);
  return Number.isNaN(number) || value === '' ? undefined : number;
};

const fixRecurrenceOptions = (
  recurrenceOptions: RecurrenceOptions
): RecurrenceOptions =>
  Object.keys(recurrenceOptions)
    .map(key => ({
      [key]: numericFields.includes(key)
        ? numberOrUndefined(recurrenceOptions[key])
        : recurrenceOptions[key],
    }))
    .reduce((prev, curr) => ({ ...prev, ...curr }), {});

const recurrenceRuleBuilder = (
  date: Date,
  recurrenceOptions: RecurrenceOptions
): RRule =>
  recurrenceRuleBuilderMain(date, fixRecurrenceOptions(recurrenceOptions));

export const nextOccurrence = (
  date: Date,
  recurrenceOptions: RecurrenceOptions
): Date => recurrenceRuleBuilder(date, recurrenceOptions).after(fixDate(date));

export default recurrenceRuleBuilder;
