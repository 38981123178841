import moment from 'moment';
import { uniqBy } from 'lodash';

import {
  CCG_BOOKING_RESTRICTION,
  NON_CCG_BOOKING_RESTRICTION,
  BookingState,
  BOOKING_STATES,
} from '../constants/bookings';
import { AVAILABILITY_STATUSES } from '../constants/fieldData';

import recurrenceRuleBuilder, {
  nextOccurrence,
  RecurrenceOptions,
} from '../services/recurrenceRuleBuilder';
import { dateFormatForAPI } from './date';

export const loadingStatuses = {
  OPENING_CLOSING: 'openingClosingTimes',
  SERVICE_TYPES: 'serviceTypes',
  AVAILABILITY: 'availability',
};

const weekdays = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
] as const;

interface Closure {
  startDate: string;
  endDate: string;
}

interface BookingItem {
  id?: number;
  date: string;
  startTime: string;
  endTime: string;
  spaceId: number;
  state: BookingState;
}

interface Slot {
  startTime: string;
  endTime: string;
  combined?: boolean;
}

interface CalendarItems {
  [date: string]: { [space: string]: Slot[] };
}

const getCombinedAvailability = states => {
  if (states.includes(AVAILABILITY_STATUSES.SERVICE_CLASH.flag)) {
    return AVAILABILITY_STATUSES.SERVICE_CLASH;
  }

  if (states.includes(AVAILABILITY_STATUSES.TIME_CLASH.flag)) {
    return AVAILABILITY_STATUSES.TIME_CLASH;
  }

  if (states.includes(AVAILABILITY_STATUSES.UNAVAILABLE.flag)) {
    return AVAILABILITY_STATUSES.TIME_CLASH;
  }

  if (states.includes(AVAILABILITY_STATUSES.AVAILABLE.flag)) {
    return AVAILABILITY_STATUSES.AVAILABLE;
  }

  return AVAILABILITY_STATUSES.UNKNOWN;
};

export const spaceDisabledForService = service => ({ clinical = false }) => {
  if (!service) return false;
  return !service.clinical && clinical;
};

export const combineSlots = (slots: Slot[] = []): Slot[] => {
  const m = time => moment(time, 'HH:mm');

  const reverseSortByTime = (
    { startTime: startA, endTime: endA },
    { startTime: startB, endTime: endB }
  ) => {
    const startDiff = m(startB).diff(m(startA));
    return startDiff === 0 ? m(endB).diff(m(endA)) : startDiff;
  };

  const canCombine = ({ endTime }, { startTime }) =>
    m(endTime).add(60, 'minutes').isSameOrAfter(m(startTime));

  const hasGap = ({ endTime }, { startTime }) =>
    m(endTime).isBefore(m(startTime));

  return [...slots].sort(reverseSortByTime).reduce(
    ([next, ...rest], curr) => [
      ...(next
        ? [
            ...(canCombine(curr, next)
              ? [
                  {
                    startTime: curr.startTime,
                    endTime: m(next.endTime).isBefore(m(curr.endTime))
                      ? curr.endTime
                      : next.endTime,
                    combined:
                      curr.combined || next.combined || hasGap(curr, next),
                  },
                ]
              : [{ combined: false, ...curr }, next]),
          ]
        : [{ combined: false, ...curr }]),
      ...rest,
    ],
    []
  );
};

export const separateSlot = (
  { startTime, endTime, ...rest }: Slot,
  availableSlots: Slot[] = []
): Slot[] => {
  const m = time => moment(time, 'HH:mm');

  const within = (slot, other) =>
    m(slot.startTime).isSameOrBefore(m(other.startTime)) &&
    m(slot.endTime).isSameOrAfter(m(other.endTime));

  const slotsWithin = availableSlots.filter(slot =>
    within({ startTime, endTime }, slot)
  );

  // Filter out slots contained within other (ie. all-day) slots
  return slotsWithin
    .filter(
      slot => slotsWithin.filter(other => within(other, slot)).length === 1
    )
    .map(slot => ({ ...slot, ...rest }));
};

export const matchSlots = <S extends Slot = Slot>(
  slots: S[],
  initialSlots: S[],
  distanceFn?: (a: S, b: S) => number = undefined,
  mergeFn?: (a: S, b: S) => S = undefined
): S[] => {
  const m = time => moment(time, 'HH:mm');

  const getDistance: (a: S, b: S) => number =
    distanceFn !== undefined
      ? distanceFn
      : (a, b) =>
          Math.abs(m(a.startTime).diff(m(b.startTime), 'minutes')) +
          Math.abs(m(a.endTime).diff(m(b.endTime), 'minutes'));

  const merge: (a: S, b: S) => S =
    mergeFn !== undefined
      ? mergeFn
      : ({ startTime, endTime }, slot) => ({
          ...slot,
          startTime,
          endTime,
        });

  const sortByMinDistance = (candidates: S[]) => (a: S, b: S) =>
    Math.min(...candidates.map(slot => getDistance(a, slot))) -
    Math.min(...candidates.map(slot => getDistance(b, slot)));

  const sortByStartTime = (a: S, b: S) => m(a.startTime).diff(m(b.startTime));

  const { matched } = [...slots].sort(sortByMinDistance(initialSlots)).reduce(
    ({ matched, unmatched }, slot) => {
      const [match, ...rest] = [...unmatched].sort(sortByMinDistance([slot]));

      return {
        matched: [...matched, match ? merge(slot, match) : slot],
        unmatched: rest,
      };
    },
    { matched: [], unmatched: initialSlots }
  );

  return [...matched].sort(sortByStartTime);
};

export const matchBookingItems = (
  items: BookingItem[],
  initialItems: BookingItem[]
): BookingItem[] => {
  const unique = values =>
    Object.keys(values.reduce((prev, curr) => ({ ...prev, [curr]: null }), {}));

  const dateSort = (a, b) => new Date(a) - new Date(b);
  const dates = unique(items.map(({ date }) => date)).sort(dateSort);

  const m = time => moment(time, 'HH:mm');

  const getDistance = (a: BookingItem, b: BookingItem): number =>
    Math.abs(m(a.startTime).diff(m(b.startTime), 'minutes')) +
    Math.abs(m(a.endTime).diff(m(b.endTime), 'minutes')) +
    (a.spaceId === b.spaceId ? 0 : 60 * 24);

  const merge = (
    { date, startTime, endTime, spaceId }: BookingItem,
    initialItem: BookingItem
  ): BookingItem => ({ ...initialItem, date, startTime, endTime, spaceId });

  return dates.flatMap(date =>
    matchSlots<BookingItem>(
      dates.length > 1 ? items.filter(item => item.date === date) : items,
      dates.length > 1
        ? initialItems.filter(item => item.date === date)
        : initialItems,
      getDistance,
      merge
    )
  );
};

const getSlotAvailability = availability => (slot: Slot) => {
  const states = Object.values(availability)
    .flat()
    .filter(
      ({ startTime, endTime }) =>
        startTime === slot.startTime && endTime === slot.endTime
    )
    .map(({ availabilityFlag }) => availabilityFlag);

  return {
    ...slot,
    available: getCombinedAvailability(states).flag,
    editable: true,
  };
};

export const getTimeSlots = (availability = {}, slots = []) =>
  slots.map(getSlotAvailability(availability));

const getSpaceAvailability = (spaceId: number, slot, slots) => {
  const states = slot
    .filter(entry => entry.spaceId === spaceId)
    .map(entry => entry.availabilityFlag);

  const [{ startTime, endTime }] = slot;

  const selectedSlot = slots.find(
    s => s.startTime === startTime && s.endTime === endTime
  );

  return {
    startTime,
    endTime,
    available: getCombinedAvailability(states).flag,
    combined: selectedSlot && selectedSlot.combined,
  };
};

export const getSlotsForSpace = (
  spaceId: number,
  slots,
  availability,
  loading = false
) => {
  if (loading) return [];

  const combinedSlots = combineSlots(slots);

  return Object.values(availability)
    .filter(slot => slot.length > 0)
    .map(slot => getSpaceAvailability(spaceId, slot, combinedSlots));
};

export const slotHasError = slotsForSpace =>
  !!slotsForSpace.length &&
  slotsForSpace.every(
    slot => slot.available !== AVAILABILITY_STATUSES.AVAILABLE.flag
  );

export const getConflicts = availability =>
  Object.values(availability)
    .flat()
    .filter(
      ({ availabilityFlag }) =>
        availabilityFlag !== AVAILABILITY_STATUSES.AVAILABLE.flag
    );

export const getBookingTotal = availability =>
  Object.values(availability)
    .flat()
    .map(({ priceWithVat = 0 }) => priceWithVat)
    .reduce((prev, curr) => prev + curr, 0);

export const getAvailabilityStatus = (
  availability,
  startTime,
  endTime,
  spaceIds = undefined
) => {
  const key = `${startTime}-${endTime}`;

  const entries = key in availability ? availability[key] : [];

  const states = entries
    .filter(({ spaceId }) => !spaceIds || spaceIds.includes(spaceId))
    .map(({ availabilityFlag }) => availabilityFlag);

  return getCombinedAvailability(states);
};

export const getMinDate = (date, recurrenceOptions = {}) =>
  moment(
    nextOccurrence(date, {
      ...recurrenceOptions,
      rangeEndType: 'count',
      count: 2,
    })
  )
    .startOf('day')
    .toDate();

export const getMaxDate = (date, { ccgCommissioned = false } = {}) =>
  moment(date)
    .startOf('day')
    .add(
      ccgCommissioned ? CCG_BOOKING_RESTRICTION : NON_CCG_BOOKING_RESTRICTION,
      'months'
    )
    .toDate();

export const mapBookingItemsToSlots = (
  bookingItems,
  status = AVAILABILITY_STATUSES.UNAVAILABLE.flag
) =>
  bookingItems.map(({ startTime, endTime }) => ({
    startTime: moment(startTime).format('HH:mm'),
    endTime: moment(endTime).format('HH:mm'),
    available: status,
  }));

const unique = (value, index, list) => list.indexOf(value) === index;

const getBookingItemDates = (items = []) =>
  items
    .map(({ startTime }) => moment(startTime).format(dateFormatForAPI))
    .filter(unique);

const getBookingItemSpaceIds = (date, items = []) =>
  items
    .filter(
      ({ startTime }) => moment(startTime).format(dateFormatForAPI) === date
    )
    .map(({ space: { id } = {} }) => id)
    .filter(spaceId => spaceId !== undefined);

export const getBookingItemFields = (items = []) =>
  getBookingItemDates(items)
    .map(date => ({
      [date]: getBookingItemSpaceIds(date, items)
        .map(spaceId => ({
          [`s${spaceId}`]: items
            .filter(
              ({ startTime }) =>
                moment(startTime).format(dateFormatForAPI) === date
            )
            .filter(({ space: { id } = {} }) => id === spaceId)
            .map(({ id, startTime, endTime, state, statementState }) => ({
              id,
              state,
              startTime: moment(startTime).format('HH:mm'),
              endTime: moment(endTime).format('HH:mm'),
              statementState,
            })),
        }))
        .reduce((prev, curr) => ({ ...prev, ...curr }), {}),
    }))
    .reduce((prev, curr) => ({ ...prev, ...curr }), {});

export const generateBookingItemFields = (
  initialDate: Date,
  spaceIds: number[],
  slots: Slot[],
  recurrence?: RecurrenceOptions = undefined
) =>
  (recurrence
    ? recurrenceRuleBuilder(initialDate, recurrence).all()
    : [initialDate]
  )
    .map(date => moment(date).format(dateFormatForAPI))
    .map(date => ({
      [date]: spaceIds
        .map(spaceId => ({
          [`s${spaceId}`]: slots,
        }))
        .reduce((prev, curr) => ({ ...prev, ...curr }), {}),
    }))
    .reduce((prev, curr) => ({ ...prev, ...curr }), {});

export const combineAvailability = (availabilityList = []) =>
  availabilityList.reduce(
    (prev, curr) =>
      Object.keys(curr)
        .map(key => ({
          [key]: key in prev ? [...prev[key], ...curr[key]] : curr[key],
        }))
        .reduce((prev_, curr_) => ({ ...prev_, ...curr_ }), prev),
    {}
  );

const filterNonEmpty = item =>
  Object.values(item).filter(value => value !== undefined).length > 0;

const mapBookingItems = (fn, items = {}) =>
  Object.keys(items)
    .sort((a, b) => new Date(a) - new Date(b))
    .map((date, rowIndex) => ({
      [date]: Object.keys(items[date])
        .map(spaceId => ({
          [spaceId]: fn(items[date][spaceId], spaceId, date, rowIndex),
        }))
        .filter(filterNonEmpty)
        .reduce((prev, curr) => ({ ...prev, ...curr }), {}),
    }))
    .filter(filterNonEmpty)
    .reduce((prev, curr) => ({ ...prev, ...curr }), {});

export const combineBookingItems = items => {
  return mapBookingItems(combineSlots, items);
};

const filterAvailability = (availability, slots, fn) =>
  combineSlots(slots)
    .map(({ startTime, endTime }) => `${startTime}-${endTime}`)
    .map(times =>
      times in availability ? { [times]: availability[times].filter(fn) } : {}
    )
    .reduce((prev, curr) => ({ ...prev, ...curr }), {});

export const getCalendarAvailability = (items = {}, availabilityList = []) => {
  const availability = combineAvailability(availabilityList);

  return mapBookingItems(
    (item, spaceId, date) =>
      filterAvailability(
        availability,
        item,
        entry => `s${entry.spaceId}` === spaceId && entry.date === date
      ),
    items
  );
};

export const filterBookingItemsBySpaceIds = (spaceIds = [], items = {}) =>
  mapBookingItems(
    (item, spaceId) =>
      spaceIds.map(id => `s${id}`).includes(spaceId) ? undefined : item,
    items
  );

export const getFirstSlotsForSpaceId = (
  spaceId: number,
  items: CalendarItems
): Slot[] => {
  const [date] = Object.keys(items)
    .filter(
      date =>
        items[date][`s${spaceId}`] && items[date][`s${spaceId}`].length > 0
    )
    .sort((a, b) => moment(a).diff(moment(b)));

  return date ? items[date][`s${spaceId}`] : [];
};

export const getSlotsForSpaceId = (
  spaceId,
  bookingItems = {},
  availabilityList = [],
  date = undefined
) =>
  availabilityList
    .flatMap(Object.values)
    .flat()
    .filter(slot => slot.spaceId === spaceId)
    .filter(slot => date === undefined || moment(slot.date).isSame(date, 'day'))
    .filter(
      slot =>
        bookingItems[slot.date] &&
        bookingItems[slot.date][`s${slot.spaceId}`] &&
        combineSlots(bookingItems[slot.date][`s${slot.spaceId}`]).some(
          ({ startTime, endTime }) =>
            startTime === slot.startTime && endTime === slot.endTime
        )
    );

export const getPriceForSpaceId = (...args) =>
  getSlotsForSpaceId(...args).reduce(
    (prev, { priceWithVat }) => prev + priceWithVat,
    0
  );

export const isClinical = spaces => spaces.some(({ clinical }) => clinical);

export const isCancellableBookingItem = bookingItem => {
  return bookingItem.cancellable;
};

export const isNotCancellableBookingItem = bookingItem => {
  return !isCancellableBookingItem(bookingItem);
};

export const isClosed = (
  date: string,
  openingTimes?: { [key: string]: string } = undefined,
  closures?: Closure[] = []
): boolean => {
  const day = weekdays[moment(date).day()];
  return (
    (openingTimes &&
      (!openingTimes[`${day}Start`] || !openingTimes[`${day}End`])) ||
    closures.some(
      ({ startDate, endDate }) =>
        moment(date).isSame(moment(startDate)) ||
        moment(date).isBetween(
          moment(startDate).startOf('day'),
          moment(endDate).endOf('day')
        )
    )
  );
};

const groupBySpace = availabilityList => {
  const slots = availabilityList.flatMap(Object.values).flat();
  const spaceIds = slots.map(({ spaceId }) => spaceId).filter(unique);

  return spaceIds.map(id => slots.filter(slot => slot.spaceId === id));
};

export const groupSpacesAndPrices = ({
  isProvisional = false,
  existingBooking = undefined,
  bookingItems = {},
  availabilityList = [],
  spaces = [],
  pricing = [],
}) => {
  if (isProvisional) {
    if (existingBooking && pricing.length === 0) {
      return uniqBy(
        existingBooking.bookingItems.map(({ space: { id, name } }) => ({
          id,
          name,
          price: existingBooking.bookingItems
            .filter(item => item.space.id === id)
            .reduce((prev, { totalCost }) => prev + Number(totalCost), 0),
          basePrice: existingBooking.bookingItems
            .filter(item => item.space.id === id)
            .reduce((prev, { totalCost }) => prev + Number(totalCost), 0),
        })),
        'id'
      );
    }

    if (existingBooking && pricing && !pricing.items) {
      return uniqBy(
        existingBooking.bookingItems.map(({ space: { id, name } }) => ({
          id,
          name,
          price: pricing
            .filter(item => item.spaceId === id)
            .reduce(
              (prev, { totalCostWithVat }) => prev + Number(totalCostWithVat),
              0
            ),
          basePrice: pricing
            .filter(item => item.spaceId === id)
            .reduce(
              (prev, { baseCostWithVat }) => prev + Number(baseCostWithVat),
              0
            ),
        })),
        'id'
      );
    }

    return [];
  }

  if (pricing.length > 0) {
    return uniqBy(
      pricing.items.map(price => ({
        id: price.id,
        name: spaces.find(({ id }) => id === Number(price.spaceId)).name,
        price: price.totalCostWithVat,
        basePrice: price.baseCostWithVat,
      })),
      'id'
    );
  }

  return uniqBy(
    groupBySpace(availabilityList)
      .flat()
      .filter(({ spaceId }) => spaces.some(({ id }) => id === Number(spaceId)))
      .map(i => ({
        id: i.spaceId,
        name: spaces.find(({ id }) => id === Number(i.spaceId))!.name,
        price: groupBySpace(availabilityList)
          .flat()
          .filter(({ spaceId }) => spaceId === Number(i.spaceId))
          .filter(slot => bookingItems[slot.date])
          .filter(slot => {
            const combinedSlots = combineSlots(
              bookingItems[slot.date][`s${slot.spaceId}`]
            );

            return combinedSlots.some(
              ({ startTime, endTime }) =>
                startTime === slot.startTime && endTime === slot.endTime
            );
          })
          .reduce((prev, { priceWithVat }) => prev + Number(priceWithVat), 0),
      })),
    'id'
  );
};

export const getBookingState = ({ bookingItems = [] } = {}) => {
  const states = bookingItems
    .map(({ state }) => state)
    .filter(state => !!state)
    .map(state =>
      state === BOOKING_STATES.expired ? BOOKING_STATES.tentative : state
    )
    .sort()
    .filter(unique);

  return states.length === 1 ? states[0] : BOOKING_STATES.tentative;
};
